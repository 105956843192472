// React imports
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

// MUI imports
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';

// MUI icons
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
// import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CallIcon from '@mui/icons-material/Call';

const MenuBar = () => {
    const [value, setValue] = useState(0);

    return (
        <Box>
            <BottomNavigation
                sx={{ minWidth: '100%', position: 'fixed', bottom: '0', bgcolor: '#9dbcff' }}
                value={value}
                onChange={(event, newValue) => { setValue(newValue) }}
            >
                <Link to='/'><BottomNavigationAction label='Home' icon={<HomeIcon />} ></BottomNavigationAction></Link>
                <Link to='/about-us'><BottomNavigationAction label='About Us' icon={<PersonIcon />} ></BottomNavigationAction></Link>
                <Link to='/contact-us'><BottomNavigationAction label='Contact Us' icon={<CallIcon />} ></BottomNavigationAction></Link>
            </BottomNavigation>
        </Box>
    )
}

export default MenuBar