// React imports
import React from 'react';
// import { useState } from 'react';2
// import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

// DB imports
// import { projectFirestore } from './firebase/config';

// Style imports
import './App.css';

// MUI component imports
import { Typography } from '@mui/material';

// React components import
import MainLayout from './layout/MainLayout';
import HeroSection from './components/HeroSection';
import Motto from './components/Motto';
import Experience from './components/Experience';
import StepsCards from './components/StepsCards';
import Knowledge from './components/Knowledge';
import ContactUsForm from './components/ContactUsForm';

// MUI components import
import Box from '@mui/material/Box';
// import CircularProgress from '@mui/material/CircularProgress';

function App() {
    // const [data, setData] = useState(null);
    // const [isLoading, setIsLoading] = useState(false);
    // const [error, setError] = useState(false);

    // useEffect(() => {
    //     setIsLoading(true)
    //     projectFirestore.collection('pedro-med-kineto-steps').get().then((snapshot) => {
    //         if (snapshot.epmty) {
    //             setError('Nothing to pull from database')
    //             setIsLoading(false)
    //         } else {
    //             let result = [];
    //             snapshot.docs.forEach((doc) => {
    //                 result.push({ id: doc.id, ...doc.data() });
    //             })
    //             setData(result);
    //             setIsLoading(false);
    //         }
    //     }).catch(err => {
    //         setError(err.message);
    //         setIsLoading(false);
    //     })
    // }, [])

    // console.log('Data', data)

    return (
        <MainLayout>
            {/* {isLoading ?
                <CircularProgress sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', margin: 'auto' }} /> :
                <Box className="home-page-main-box">
                    <HeroSection />
                    <Motto />
                    <Experience />
                    <StepsCards />
                    <Knowledge />
                    <ContactUsForm />
                </Box>
            }
            {error} */}

            <Box className="home-page-main-box">
                <HeroSection />
                <Motto />
                <Experience />
                <StepsCards />
                <Knowledge />
                <ContactUsForm />
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '70px' }}>
                    <Typography
                        component="div"
                        sx={{ flexGrow: 1, fontWeight: '700', fontSize: '12px' }}
                    >
                        © 2023 Pedro Med Kineto. All rights reserved – Developed by  <Typography component='span' sx={{ color: '#ba0101', fontSize: '12px', textDecoration: 'none' }}>
                            <Typography component='a' href='https://www.standwebsolutions.com' target='_blank' sx={{ color: '#ba0101', fontSize: '12px', textDecoration: 'none' }}>STAND Web Solutions</Typography>
                        </Typography>
                    </Typography>
                </Box>
            </Box>

            <ToastContainer />
        </MainLayout>
    );
}

export default App;
