// React imports
import React from 'react';

// MUI component imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/system';
import { Divider } from '@mui/material';

const Experience = () => {
    return (
        <Box sx={{ width: '100%', margin: '100px auto 100px auto', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Typography variant='h2' className="experience-title-text">Who we cover</Typography>
            <Divider variant="middle" sx={{ borderBottomWidth: 3, background: '#94b0da', width: '200px', margin: 'auto' }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: '50px'}} className="experience-main-box">
                <Box sx={{ display: 'flex', margin: 'auto 0' }} >
                    <Stack spacing={5} sx={{ height: '400px'}} className="experience-organisations-box">
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignSelf: 'start', textAlign: 'start' }}>
                            <Typography variant='h4' className="experience-title-text">Small businesses</Typography>
                            <Divider variant="middle" sx={{ borderBottomWidth: 3, background: '#94b0da', width: '200px', margin: '5px auto 10px 0' }} />
                            <Typography sx={{ width: '250px' }}>SMBs are priviledged of our services and usually offer their employees a fully paid 'kineto' package included in their work contract.</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignSelf: 'start', textAlign: 'start' }}>
                            <Typography variant='h4' className="experience-title-text">Large businesses</Typography>
                            <Divider variant="middle" sx={{ borderBottomWidth: 3, background: '#94b0da', width: '200px', margin: '5px auto 10px 0' }} />
                            <Typography sx={{ width: '250px' }}>Large organisations are actively looking for our tyoe or services, where our specialists perform the sessions right on the company premisses.</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignSelf: 'start', textAlign: 'start' }}>
                            <Typography variant='h4' className="experience-title-text">Individuals</Typography>
                            <Divider variant="middle" sx={{ borderBottomWidth: 3, background: '#94b0da', width: '200px', margin: '5px auto 10px 0' }} />
                            <Typography sx={{ width: '250px' }}>Any individual on its own or after a surgical intervention wishing to recover fully, benefits entirely of our services in the confort of their own house.</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ width: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto 0' }} className="experience-years-box">
                    <Paper elevation={4} sx={{ borderRadius: '50%', height: '300px', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center' }}>
                        <Typography variant='h2' className="experience-title-text">10+</Typography>
                        <Typography variant='h6'>years of experience</Typography>
                    </Paper>
                </Box>
            </Box>

        </Box>
    )
}

export default Experience