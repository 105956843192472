// React imports
import React from 'react';

// Media imports
import ProfilePhoto from '../assets/profile-photo.jpeg'

// React component imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const Knowledge = () => {
    return (
        <Box sx={{ backgroundColor: '#e2725b1f' }} >
            <Box sx={{ display: 'flex', fleDirection: 'column', justifyContent: 'center', marginX: '12vw', marginY: '100px', textAlign: 'start' }} className="knowledge-main-box">
                <Box component='span' sx={{ margin: 'auto', padding: '10px' }}>
                    <Paper elevation={3} sx={{ borderRadius: '25%' }}>
                        <img src={ProfilePhoto} alt="" className="gallery-profile-photo" />
                    </Paper>

                </Box>
                <Box component='span' sx={{ margin: 'auto', padding: '10px' }}>
                    <Typography variant='h3'>Our knowledge...</Typography>
                    <Typography>Physical therapists are trained and licensed movement experts. They can diagnose and treat a range of injuries, disabilities, and health conditions. Physical therapists aim to improve a person’s range of movement and quality of life and prevent further injury or disability. Licensed physical therapists work in a range of healthcare settings, including outpatient offices, private practices, hospitals, rehabilitation centers, nursing homes, home health, sports and fitness settings, schools, hospices, occupational settings, government agencies, and research centers.</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Knowledge