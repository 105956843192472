// React imports
import React from 'react';
import { Link } from 'react-router-dom';

// MUI components import
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const HeroSection = () => {
    return (
        <Box className="home-page-welcome-box">
            <Typography variant='h5' className="homepage-title">Welcome to</Typography>
            <Typography variant='h2' className="homepage-title" sx={{ paddingTop: '30px' }}>Pedro Med Kineto</Typography>
            <Typography variant='h6' className="homepage-title" sx={{ paddingTop: '30px' }}>Specialised in kinetotherapy, body recovery and healthy living</Typography>

            <Box sx={{ marginY: '20px', marginX: 'auto', width: '20%' }}>
                <Divider variant="middle" sx={{ borderBottomWidth: 3, background: '#94b0da' }} />
            </Box>

            <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
                <Stack direction="row" spacing={2}>
                    <Link to='contact-us'><Button variant="outlined">Contact</Button></Link>
                </Stack>
            </Box>
        </Box>
    )
}

export default HeroSection