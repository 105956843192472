// React imports
import React from 'react';
import ContactUsForm from '../components/ContactUsForm';

// React components import
import MainLayout from '../layout/MainLayout';
import { Box, ListItem, Typography } from '@mui/material';

// MUI icon imports
import EmailIcon from '@mui/icons-material/Email';

const ContactUs = () => {

    return (
        <MainLayout>
            <Box className="contact-us-page-box">
                <ContactUsForm />
                <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '100px', margin: 'auto' }}>
                    <EmailIcon />
                    <ListItem sx={{ paddingTop: '0' }}>
                        <a href="mailto:petre@pedro-med-kineto.ro" target="_top"><Typography>petre@pedro-med-kineto.ro</Typography></a>
                    </ListItem>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '70px' }}>
                    <Typography
                        component="div"
                        sx={{ flexGrow: 1, fontWeight: '700', fontSize: '12px' }}
                    >
                        © 2023 Pedro Med Kineto. All rights reserved – Developed by  <Typography component='span' sx={{ color: '#ba0101', fontSize: '12px', textDecoration: 'none' }}>
                            <Typography component='a' href='https://www.standwebsolutions.com' target='_blank' sx={{ color: '#ba0101', fontSize: '12px', textDecoration: 'none' }}>STAND Web Solutions</Typography>
                        </Typography>
                    </Typography>
                </Box>
            </Box>
        </MainLayout>
    )
}

export default ContactUs