// React imports
import React from 'react';


// MUI component imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Motto = () => {
    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '100px' }} className="motto-main-box">
                <Typography variant='h6'>“A body free from nervous tension and fatigue is the ideal shelter provided by nature for housing a well-balanced mind, fully capable of successfully meeting all the complex problems of modern living.”</Typography>
                <Typography variant='body2' sx={{ marginX: '80px', marginY: '20px', textAlign: 'end', fontStyle: 'italic' }}>– Joseph Pilates</Typography>
            </Box>
        </Box>

    )
}

export default Motto