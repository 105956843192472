// React imports
import React from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

// MUI component imports
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const ContactUsForm = () => {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_a27hoj7', 'template_sct60wn', form.current, 'g0LH1KIfkx5Ccoj9n')
            .then((result) => {
                console.log(result.text);
                e.target.reset();
                toast.success('Email sent successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <Box className="contact-us-form-component-box" sx={{ marginTop: '100px' }}>
            <Typography variant='h4' sx={{ textTransform: 'uppercase' }}>Get in touch</Typography>
            <div id="form">
                <form ref={form} onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" name="user_name" className="input" />
                    <label>Email</label>
                    <input type="email" name="user_email" className="input" />
                    <label>Message</label>
                    <textarea name="message" />
                    <input type="submit" value="Send" className="input" rows='1'/>
                </form>
            </div>
        </Box>
    )
}

export default ContactUsForm