// React imports
import React from 'react';

// MUI component imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

// MUI icon imports
import CallIcon from '@mui/icons-material/Call';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const StepsCards = () => {
    return (
        <Box sx={{ marginBottom: '100px' }}>
            <Box sx={{ display: 'flex', fleDirection: 'column', justifyContent: 'center', marginX: '20px', marginY: '100px', textAlign: 'center' }}>
                <Typography variant='h5'>Three simple steps that you can take right now for a helthier <span className="steps-cards-title-span-box">YOU</span></Typography>
            </Box>

            <Box sx={{ display: 'flex', fleDirection: 'row', justifyContent: 'center', alignItems: 'center' }} className="steps-cards-box">
                <Paper square elevation={3} sx={{ width: '250px', height: '270px', marginX: '40px', backgroundColor: '#e2725b1f' }} className="steps-cards-paper">
                    <Typography variant='h6' sx={{ textTransform: 'uppercase', fontWeight: 'bolder', padding: '10px' }}>Contact initiation</Typography>
                    <Box sx={{ padding: '10px' }}>
                        <CallIcon fontSize='large'/>
                    </Box>
                    <Divider variant="middle" sx={{ borderBottomWidth: 1, margin: '10px',background: '#94b0da' }} />
                    <Typography variant='' sx={{ textWrap: "wrap" }}>Give us a quick call and we will schedule you in for a complete body assesment</Typography>
                </Paper>
                <Paper square elevation={3} sx={{ width: '250px', height: '270px', marginX: '40px', backgroundColor: '#e2725b1f' }} className="steps-cards-paper">
                    <Typography variant='h6' sx={{ textTransform: 'uppercase', fontWeight: 'bolder', padding: '10px' }}>Kineto recovery</Typography>
                    <Box sx={{ padding: '10px' }}>
                        <SportsGymnasticsIcon fontSize='large'/>
                    </Box>
                    <Divider variant="middle" sx={{ borderBottomWidth: 1, margin: '10px',background: '#94b0da' }} />
                    <Typography variant='' sx={{ textWrap: "wrap" }}>Start your recovery with healthy and efficient kinetotherapy exercises</Typography>
                </Paper>
                <Paper square elevation={3} sx={{ width: '250px', height: '270px', marginX: '40px', backgroundColor: '#e2725b1f' }} className="steps-cards-paper">
                    <Typography variant='h6' sx={{ textTransform: 'uppercase', fontWeight: 'bolder', padding: '10px' }}>Incress strength</Typography>
                    <Box sx={{ padding: '10px' }}>
                        <FitnessCenterIcon fontSize='large'/>
                    </Box>
                    <Divider variant="middle" sx={{ borderBottomWidth: 1, margin: '10px',background: '#94b0da' }} />
                    <Typography variant='' sx={{ textWrap: "wrap" }}>Build healthy fitness habbits & increase your mobility and muscular mass</Typography>
                </Paper>
            </Box>
        </Box>
    )
}

export default StepsCards