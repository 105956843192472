// React imports
import React from 'react'

// React components import
import MainLayout from '../layout/MainLayout';

// MUI components import
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const AboutUs = () => {
    return (
        <MainLayout>
            <Box className="about-us-main-box">
                <Box sx={{ m: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'end' }}>
                    <Typography variant='h4' sx={{ mb: 5 }}>What is kinetotherapy?</Typography>
                    <Typography variant='body1' sx={{ my: 1 }}>Also known as recovery gymnastics or medical gymnastics, physical therapy or movement therapy is an important step in recovering and maintaining health, as well as in preventing physical deficiencies.</Typography>
                    <Typography variant='body1' sx={{ my: 1 }}>Kinetotherapy stimulates the body’s adaptation resources through controlled and properly dosed exercise.</Typography>
                    <Typography variant='body1' sx={{ my: 1 }}>It focuses on performing the right movements, improving physical performance, increasing endurance, correcting poor posture, increasing joint amplitude and correcting physical deficits.</Typography>
                    <Typography variant='body1' sx={{ my: 1 }}>Kinetotherapy programs are specially created according to the characteristics of each patient, age, constitution and type of activity and will be closely monitored by a physiotherapist.</Typography>
                    <Typography variant='body1' sx={{ my: 1 }}></Typography>
                </Box>
                <Box sx={{ m: 8, pb: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'end' }}>
                    <Typography variant='h5' sx={{ mb: 5 }}>What I offer...</Typography>
                    <Typography variant='body1'>Kinetotherapy</Typography>
                    <Typography variant='body1'>Therapeutic massage</Typography>
                    <Typography variant='body1'>Reflexotherapy</Typography>
                    <Typography variant='body1'>Lymphatic drainage massage</Typography>
                    <Typography variant='body1'>Anti cellulite massage</Typography>
                    <Typography variant='body1'>Deep tissue</Typography>
                    <Typography variant='body1'>Relaxing massage</Typography>

                    <Typography variant='h5' sx={{ mt: 5 }}>...and what are the benefits of kinetotherapy?</Typography>
                    <Typography variant='body1'>Relieves pain</Typography>
                    <Typography variant='body1'>Improves mobility</Typography>
                    <Typography variant='body1'>Contributes to faster recovery from sports injuries</Typography>
                    <Typography variant='body1'>Reduces the risk of injury</Typography>
                    <Typography variant='body1'>Increases strength, flexibility and physical exertion</Typography>
                    <Typography variant='body1'>Improves balance and coordination disorders</Typography>
                    <Typography variant='body1'>Tones muscles</Typography>
                    <Typography variant='body1'>Helps regain independence and functionality</Typography>
                    <Typography variant='body1'>Helps maintain health</Typography>
                    <Typography variant='body1'>Fights the effects of aging and an unhealthy lifestyle.</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', paddingTop: '30px' }} className="about-us-developed-by-box">
                        <Typography
                            component="div"
                            sx={{ flexGrow: 1, fontWeight: '700', fontSize: '12px' }}
                        >
                            © 2023 Pedro Med Kineto. All rights reserved – Developed by  <Typography component='span' sx={{ color: '#ba0101', fontSize: '12px', textDecoration: 'none' }}>
                                <Typography component='a' href='https://www.standwebsolutions.com' target='_blank' sx={{ color: '#ba0101', fontSize: '12px', textDecoration: 'none' }}>STAND Web Solutions</Typography>
                            </Typography>
                        </Typography>
                    </Box>
                </Box>

            </Box>
        </MainLayout>

    )
}

export default AboutUs