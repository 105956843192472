// React imports
import React from 'react';

// MUI components import
import { Box } from '@mui/material';

// React components import
import MenuBar from '../components/MenuBar';

const MainLayout = ({ children }) => {
    return (
        <Box>
            <MenuBar></MenuBar>
            <Box>{children}</Box>
        </Box>
    )
}

export default MainLayout